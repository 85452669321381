import {
  UilInstagram,
  UilWhatsapp,
  UilGithubAlt,
  UilLinkedinAlt,
  UilMessage,
  UilMouseAlt,
  UilAngleDown,
  UilBracketsCurly,
  UilPalette,
  UilLinux,
  UilAndroid,
  UilServerNetwork,
  UilGraduationCap,
  UilBriefcaseAlt,
  UilCalendarAlt,
  UilArrowRight,
  //   UilAngleRight,
  //   UilAngleLeft,
  UilPhone,
  UilEnvelope,
  UilMapMarker,
} from "@iconscout/react-unicons";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Nav } from "react-bootstrap";
import { useEffect } from "react";
// import "../main.js";

const Main = () => {
  const optionsParticipants = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 1,
      },
      700: {
        items: 1,
      },
      900: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    const skillsContent = document.getElementsByClassName("skills__content"),
      skillsHeader = document.querySelectorAll(".skills__header");

    function toggleSkills() {
      let itemClass = this.parentNode.className;

      for (let i = 0; i < skillsContent.length; i++) {
        skillsContent[i].className = "skills__content skills__close";
      }

      if (itemClass === "skills__content skills__close") {
        this.parentNode.className = "skills__content skills__open";
      }
    }

    skillsHeader.forEach((el) => {
      el.addEventListener("click", toggleSkills);
    });

    const tabs = document.querySelectorAll("[data-target]"),
      tabContents = document.querySelectorAll("[data-content]");

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        const target = document.querySelector(tab.dataset.target);

        tabContents.forEach((tabContent) => {
          tabContent.classList.remove("qualification__active");
        });
        target.classList.add("qualification__active");

        tabs.forEach((tab) => {
          tab.classList.remove("qualification__active");
        });
        tab.classList.add("qualification__active");
      });
    });

    function scrollUp() {
      const scrollUp = document.getElementById("scroll-up");
      if (this.scrollY >= 560) {
        scrollUp.classList.add("show-scroll");
      } else {
        scrollUp.classList.remove("show-scroll");
      }
    }

    window.addEventListener("scroll", scrollUp);
  }, []);

  return (
    <main className="main">
      {/* Home Section */}
      <section className="home section" id="home">
        <div className="home__container container grid">
          <div className="home__content grid">
            <div className="home__social">
              <a
                href="https://www.instagram.com/x000000exp/"
                className="home__social-icon"
              >
                <UilInstagram size="20" />
              </a>
              <a
                href="https://www.linkedin.com/in/nurkhaliq-futhra-maulana-abbb2a199/"
                className="home__social-icon"
              >
                <UilLinkedinAlt size="20" />
              </a>
              <a
                href="https://github.com/nurkhaliqfm"
                className="home__social-icon"
              >
                <UilGithubAlt size="20" />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=6281241285382text="
                className="home__social-icon"
              >
                <UilWhatsapp size="20" />
              </a>
            </div>

            <div className="home__img">
              <svg
                className="home__blob"
                viewBox="0 0 479 467"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <mask id="mask0" mask-type="alpha">
                  <path d="M9.19024 145.964C34.0253 76.5814 114.865 54.7299 184.111 29.4823C245.804 6.98884 311.86 -14.9503 370.735 14.143C431.207 44.026 467.948 107.508 477.191 174.311C485.897 237.229 454.931 294.377 416.506 344.954C373.74 401.245 326.068 462.801 255.442 466.189C179.416 469.835 111.552 422.137 65.1576 361.805C17.4835 299.81 -17.1617 219.583 9.19024 145.964Z" />
                </mask>
                <g mask="url(#mask0)">
                  <path d="M9.19024 145.964C34.0253 76.5814 114.865 54.7299 184.111 29.4823C245.804 6.98884 311.86 -14.9503 370.735 14.143C431.207 44.026 467.948 107.508 477.191 174.311C485.897 237.229 454.931 294.377 416.506 344.954C373.74 401.245 326.068 462.801 255.442 466.189C179.416 469.835 111.552 422.137 65.1576 361.805C17.4835 299.81 -17.1617 219.583 9.19024 145.964Z" />
                  <image
                    className="home__blob-img"
                    x="15"
                    y="15"
                    href={process.env.PUBLIC_URL + "/assets/img/perfil.png"}
                  />
                </g>
              </svg>
            </div>

            <div className="home__data">
              <h1 className="home__title">Hi, I'am Nurkhaliq</h1>
              <h3 className="home__subtitle">
                Fullstack Web Developer & Android Developer
              </h3>
              <p className="home__description">
                Have much experience in disign and develope in web and android
                field
              </p>
              <Nav.Link href="#contactme" className="button button--flex">
                Contact Me&nbsp;
                <UilMessage size="20" />
              </Nav.Link>
            </div>
          </div>

          <div className="home__scroll">
            <Nav.Link
              href="#about"
              className="home__scroll-button button--flex"
            >
              <UilMouseAlt />
              <div className="home__scroll-name">Scroll Down</div>
              <UilAngleDown />
            </Nav.Link>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="about section" id="about">
        <h2 className="section__title">About Me</h2>
        <span className="section__subtitle">My Introduction</span>

        <div className="about__container container grid">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/about.png"}
            alt="codefm.my.id"
            className="about__img"
          />
          <div className="about__data">
            <p className="about__description">
              Web Developer & Android Developer, with extensive knowledge and
              experience. Now I start to making my own Project UI/UX.
            </p>
            <div className="about__info">
              <div>
                <span className="about__info-title">02+</span>
                <span className="about__info-name">
                  Years <br />
                  experience
                </span>
              </div>
              <div>
                <span className="about__info-title">20+</span>
                <span className="about__info-name">
                  Completed <br />
                  project
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section className="skills section" id="skills">
        <h2 className="section__title">Skills</h2>
        <span className="section__subtitle">My Technical Level</span>

        <div className="skills__container container grid">
          <div className="skills__content skills__open">
            <div className="skills__header">
              <UilBracketsCurly className="skills__icon" />
              <div>
                <div className="skills_title">Frontend Developer</div>
              </div>
              <UilAngleDown className="skills__arrow" />
            </div>
            <div className="skills__list grid">
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">HTML</h3>
                  <span className="skills__number">95%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__html"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">CSS</h3>
                  <span className="skills__number">80%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__css"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Javascript</h3>
                  <span className="skills__number">75%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__js"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">React Js</h3>
                  <span className="skills__number">75%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__rjs"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Bootstrap</h3>
                  <span className="skills__number">85%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__bs"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Tailwind CSS</h3>
                  <span className="skills__number">75%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__tw"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="skills__content skills__close">
            <div className="skills__header">
              <UilServerNetwork className="skills__icon" />
              <div>
                <div className="skills_title">Backend Developer</div>
              </div>
              <UilAngleDown className="skills__arrow" />
            </div>
            <div className="skills__list grid">
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">PHP</h3>
                  <span className="skills__number">80%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__php"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Express Js</h3>
                  <span className="skills__number">70%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__ejs"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Firebase</h3>
                  <span className="skills__number">65%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__firebase"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">MySql</h3>
                  <span className="skills__number">85%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__mysql"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">CodeIgniter</h3>
                  <span className="skills__number">80%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__ci"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="skills__content skills__close">
            <div className="skills__header">
              <UilAndroid className="skills__icon" />
              <div>
                <div className="skills_title">Android developer</div>
              </div>
              <UilAngleDown className="skills__arrow" />
            </div>
            <div className="skills__list grid">
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Java (Android Studio)</h3>
                  <span className="skills__number">70%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__java"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Flutter</h3>
                  <span className="skills__number">70%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__flutter"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="skills__content skills__close">
            <div className="skills__header">
              <UilLinux className="skills__icon" />
              <div>
                <div className="skills_title">Operating System & Server</div>
              </div>
              <UilAngleDown className="skills__arrow" />
            </div>
            <div className="skills__list grid">
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Linux</h3>
                  <span className="skills__number">80%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__linux"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="skills__content skills__close">
            <div className="skills__header">
              <UilPalette className="skills__icon" />
              <div>
                <div className="skills_title">Design</div>
              </div>
              <UilAngleDown className="skills__arrow" />
            </div>
            <div className="skills__list grid">
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Figma</h3>
                  <span className="skills__number">75%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__figma"></span>
                </div>
              </div>
              <div className="skills_data">
                <div className="skills_titles">
                  <h3 className="skills_name">Inkscape</h3>
                  <span className="skills__number">80%</span>
                </div>
                <div className="skills_bar">
                  <span className="skills__percentage skills__ink"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Qualification Section */}
      <section className="qualification section">
        <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">My Personal Journey</span>

        <div className="qualification__container container grid">
          <div className="qualification__tabs">
            <div
              className="qualification__button button--flex qualification__active"
              data-target="#education"
            >
              <UilGraduationCap /> Education
            </div>
            <div
              className="qualification__button button--flex"
              data-target="#training"
            >
              <UilBriefcaseAlt /> Training
            </div>
          </div>

          <div className="qualification__sections">
            <div
              className="qualification__content qualification__active"
              data-content
              id="education"
            >
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">
                    Theoretical and Computational Physics
                  </h3>
                  <span className="qualification__subtitle">
                    Hasanuddin University
                  </span>
                  <div className="qualification__calender">
                    <UilCalendarAlt size="15" />
                    &nbsp; 2015 - 2020
                  </div>
                </div>
                <div>
                  <div className="qualification__rounder"></div>
                  <div className="qualification__line"></div>
                </div>
              </div>
            </div>

            <div className="qualification__content" data-content id="training">
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">
                    Programming HTML, CSS, Javascript
                  </h3>
                  <span className="qualification__subtitle">
                    Progate - Digital Telent
                  </span>
                  <div className="qualification__calender">
                    <UilCalendarAlt size="15" />
                    &nbsp; 2020
                  </div>
                </div>
                <div>
                  <div className="qualification__rounder"></div>
                  <div className="qualification__line"></div>
                </div>
              </div>
              <div className="qualification__data">
                <div></div>
                <div>
                  <div className="qualification__rounder"></div>
                  <div className="qualification__line"></div>
                </div>
                <div>
                  <h3 className="qualification__title">
                    Programming Essential in Python
                  </h3>
                  <span className="qualification__subtitle">
                    Cisco Network Academy
                  </span>
                  <div className="qualification__calender">
                    <UilCalendarAlt size="15" />
                    &nbsp; 2019
                  </div>
                </div>
              </div>
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">
                    Introduction to Kotlin
                  </h3>
                  <span className="qualification__subtitle">Dicoding</span>
                  <div className="qualification__calender">
                    <UilCalendarAlt size="15" />
                    &nbsp; 2020
                  </div>
                </div>
                <div>
                  <div className="qualification__rounder"></div>
                  <div className="qualification__line"></div>
                </div>
              </div>
              <div className="qualification__data">
                <div></div>
                <div>
                  <div className="qualification__rounder"></div>
                  <div className="qualification__line"></div>
                </div>
                <div>
                  <h3 className="qualification__title">
                    Basic Android Development With Java
                  </h3>
                  <span className="qualification__subtitle">Dicoding</span>
                  <div className="qualification__calender">
                    <UilCalendarAlt size="15" />
                    &nbsp; 2019
                  </div>
                </div>
              </div>
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">
                    Basic Machine Learning
                  </h3>
                  <span className="qualification__subtitle">Dicoding</span>
                  <div className="qualification__calender">
                    <UilCalendarAlt size="15" />
                    &nbsp; 2020
                  </div>
                </div>
                <div>
                  <div className="qualification__rounder"></div>
                  <div className="qualification__line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section className="portfolio section" id="portfolio">
        <h2 className="section__title">Portfolio</h2>
        <span className="section__subtitle">Most Recent Work</span>

        <div className="portfolio__container container ">
          <OwlCarousel
            className="owl-theme item-about__content"
            {...optionsParticipants}
          >
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame1.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Perpustakaan Digital STIA AL-Gazali Barru
                </h3>
                <p className="portfolio__description">
                  Perpus Digital Kampus & Pinjam Buku
                </p>
                <a
                  href="https://lib.stiaalgazali.ac.id"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame2.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Lending Page Galery Stand Fasya
                </h3>
                <p className="portfolio__description">Landing Page</p>
                <a
                  href="https://magnificent-cannoli-6cbaa3.netlify.app/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame3.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">Lending Page ASH Store</h3>
                <p className="portfolio__description">Landing Page</p>
                <a
                  href="https://wonderful-biscotti-6479a5.netlify.app/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame4.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Web Kampus STIA AL-Gazali Barru
                </h3>
                <p className="portfolio__description">
                  Web Kampus/Sekolah/Perusahaan
                </p>
                <a
                  href="https://stiaalgazali.ac.id"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame5.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Preliminary Test Teknik Industri Unhas 2023
                </h3>
                <p className="portfolio__description">Online Test</p>
                <a
                  href="https://constrain-test.hmtiftuh.com//"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame6.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Lending Page Turkish Propolish
                </h3>
                <p className="portfolio__description">Landing Page</p>
                <a
                  href="https://spontaneous-gecko-06e86b.netlify.app/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame7.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">Lending Lemota</h3>
                <p className="portfolio__description">Landing Page</p>
                <a
                  href="https://effervescent-cobbler-efd48c.netlify.app/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame8.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Lending Page Event Constrain 2023
                </h3>
                <p className="portfolio__description">Landing Page</p>
                <a
                  href="https://constrain.hmtiftuh.com/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame9.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">Idemy By Anhar Foundation</h3>
                <p className="portfolio__description">Online Class</p>
                <a
                  href="https://anharfoundation.com/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame10.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Android App Pangoje Luwu Timur
                </h3>
                <p className="portfolio__description">Andorid App</p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mastertechindonesia.pangoje"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame11.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">Shculer.ID</h3>
                <p className="portfolio__description">Online Class</p>
                <a
                  href="https://schuler.id/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame12.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">
                  Sistem Akademik STIA AL-Gazali Barru
                </h3>
                <p className="portfolio__description">
                  Sistem Akademik Kampus/LMS
                </p>
                <a
                  href="https://siakad.stiaalgazali.ac.id/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
            <div className="portfolio__content grid">
              <img
                src={process.env.PUBLIC_URL + "assets/img/Frame13.png"}
                alt="codefm.my.id"
                className="portfolio__img"
              />

              <div className="portfolio__data">
                <h3 className="portfolio__title">Wedding Invitation</h3>
                <p className="portfolio__description">Event Invitation</p>
                <a
                  href="https://roaring-banoffee-778892.netlify.app/"
                  className="button button--flex button--small portfolio__button"
                >
                  Visit &nbsp; <UilArrowRight />
                </a>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      {/* Contact Me Section */}
      <section className="contact section" id="contactme">
        <h2 className="section__title">Contact Me</h2>
        <span className="section__subtitle">Get in touch</span>

        <div className="contact__container container grid">
          <div>
            <div className="contact__information">
              <UilPhone className="contact__icon" size="32" />
              <div>
                <h3 className="contact__title">Call Me</h3>
                <span className="contact__subtitle">0812-4128-5382</span>
              </div>
            </div>
            <div className="contact__information">
              <UilEnvelope className="contact__icon" size="32" />
              <div>
                <h3 className="contact__title">Email</h3>
                <span className="contact__subtitle">
                  nurkhaliqfuthra@codefm.my.id
                </span>
              </div>
            </div>
            <div className="contact__information">
              <UilMapMarker className="contact__icon" size="50" />
              <div>
                <h3 className="contact__title">Location</h3>
                <span className="contact__subtitle">
                  BTP Residence Block AD No. 1636, Keberkahan Raya Street,
                  Makassar City
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Main;
