import {
  UilEstate,
  UilFileAlt,
  UilUser,
  UilScenery,
  UilMessage,
  UilTimes,
  UilApps,
} from "@iconscout/react-unicons";
import { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";

const NavigationBar = () => {
  const [active, setActive] = useState("home");

  useEffect(() => {
    const navMenu = document.getElementById("nav-menu"),
      navToggle = document.getElementById("nav-toggle"),
      navClose = document.getElementById("nav-close");

    if (navToggle) {
      navToggle.addEventListener("click", () => {
        navMenu.classList.add("show-menu");
      });
    }

    // Hide Menu
    if (navClose) {
      navClose.addEventListener("click", () => {
        navMenu.classList.remove("show-menu");
      });
    }

    const sections = document.querySelectorAll("section[id]");

    function scrollActive() {
      const scrollY = window.pageYOffset;

      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;
        let sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document
            .querySelector(".nav__menu a[href*=" + sectionId + "]")
            .classList.add("active-link");
        } else {
          document
            .querySelector(".nav__menu a[href*=" + sectionId + "]")
            .classList.remove("active-link");
        }
      });
    }
    window.addEventListener("scroll", scrollActive);

    function scrollHeader() {
      const nav = document.getElementById("header");
      if (this.scrollY >= 80) {
        nav.classList.add("scroll-header");
      } else {
        nav.classList.remove("scroll-header");
      }
    }
    window.addEventListener("scroll", scrollHeader);
  }, []);

  return (
    <header className="header" id="header">
      <nav className="nav container">
        <a className="nav__logo" target={"_blank"} rel="noreferrer" href="/">
          Codefm
        </a>
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list grid">
            <li className="nav__item">
              <Nav.Link
                className={
                  active === "home" ? " nav__link active-link" : "nav__link"
                }
                href="#home"
                onClick={() => setActive("home")}
              >
                <UilEstate className="nav__icon" />
                Home
              </Nav.Link>
            </li>
            <li className="nav__item">
              <Nav.Link
                className={
                  active === "about" ? " nav__link active-link" : "nav__link"
                }
                href="#about"
                onClick={() => setActive("about")}
              >
                <UilUser className="nav__icon" />
                About
              </Nav.Link>
            </li>
            <li className="nav__item">
              <Nav.Link
                className={
                  active === "skills" ? " nav__link active-link" : "nav__link"
                }
                href="#skills"
                onClick={() => setActive("skills")}
              >
                <UilFileAlt className="nav__icon" />
                Skills
              </Nav.Link>
            </li>
            <li className="nav__item">
              <Nav.Link
                className={
                  active === "portfolio"
                    ? " nav__link active-link"
                    : "nav__link"
                }
                href="#portfolio"
                onClick={() => setActive("portfolio")}
              >
                <UilScenery className="nav__icon" />
                Portfolio
              </Nav.Link>
            </li>
            <li className="nav__item">
              <Nav.Link
                className={
                  active === "contactme"
                    ? " nav__link active-link"
                    : "nav__link"
                }
                href="#contactme"
                onClick={() => setActive("contactme")}
              >
                <UilMessage className="nav__icon" />
                ContactMe
              </Nav.Link>
            </li>
          </ul>
          <UilTimes className="nav__close" id="nav-close" />
        </div>

        <div className="nav__btns">
          <div className="nav__toggle" id="nav-toggle">
            <UilApps size="18" />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavigationBar;
