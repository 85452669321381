import { UilInstagram, UilWhatsapp } from "@iconscout/react-unicons";
import { Nav } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__bg">
        <div className="footer__container container grid">
          <div>
            <h1 className="footer__title">Codefm</h1>
            <span className="footer__subtitle">
              Fullstack Web Developer & Android Developer
            </span>
          </div>

          <ul className="footer__links">
            <li>
              <Nav.Link href="#portfolio" className="footer__link">
                Portfolio
              </Nav.Link>
            </li>
            <li>
              <Nav.Link href="#contactme" className="footer__link">
                Contact
              </Nav.Link>
            </li>
          </ul>

          <div className="footer__social">
            <a
              href="https://www.instagram.com/x000000exp/"
              className="footer__social"
            >
              <UilInstagram />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=6281241285382text="
              className="footer__social"
            >
              <UilWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
