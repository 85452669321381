import "./style/style.css";
import NavigationBar from "./components/NavigationBar";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { UilArrowUp } from "@iconscout/react-unicons";
import { Nav } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <Main />
      <Footer />
      <Nav.Link href="#home" className="scrollup" id="scroll-up">
        <UilArrowUp className="scrollup__icon" size="24" />
      </Nav.Link>
    </div>
  );
}

export default App;
